.skeleton-image,
.skeleton-title,
.skeleton-text {
  background-color: #e9ecef;
  border-radius: 4px;
}

.animate-pulse {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

@keyframes pulse {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}